<template>
  <v-container>
    <v-progress-circular
      v-if="loading"
      :size="50"
      color="primary"
      class="center"
      indeterminate
    />
    <div v-if="!loading" class="mt-4 mb-4">
      <h1 class="headline mb-4">
        {{ event.name.replace(moment(event.date).format("YYYY"), "") }}
        {{ moment(event.date).format("YYYY") }}
      </h1>
      <v-card
        v-if="event.type !== 'Gallery' && allowed && event.active"
        class="shadow"
      >
        <v-card-text>
          <div
            v-if="
              event.id === '5A6B36A06631358533000000' ||
              event.id === '5A6B366B6631357817000000' ||
              event.id === '5ccbf580e91445f467000002'
            "
          >
            Bitte geben Sie auch den Buchstaben (f,w oder s) Ihrer Startnummer
            ein um Ihre Bilder zu sehen.
          </div>
          <div
            v-if="
              event.id === '663527e875243f0018a4a613'
            "
          >
            Frauen müssen auch den Buchstaben f Ihrer Startnummer eingeben
            um Ihre Bilder zu sehen.
          </div>
          <div
            v-if="
              event.id === '5b18d937753ab30f49debbd1' ||
              event.id === '5b55a4c2609a7b3d2a000002'
            "
          >
            Bitte geben Sie auch den Buchstaben (w) wenn vorhanden Ihrer
            Startnummer ein um Ihre Bilder zu sehen.
          </div>
          <div v-if="event.id === '5bbb5bfb51160a285c000003'">
            Bitte geben Sie auch die Buchstaben wenn vorhanden Ihrer Startnummer
            ein um Ihre Bilder zu sehen.
          </div>
          <div
            v-if="
              event.id !== '5A6B36A06631358533000000' &&
              event.id !== '5A6B366B6631357817000000' &&
              event.id !== '5b18d937753ab30f49debbd1' &&
              event.id !== '5b55a4c2609a7b3d2a000002' &&
              event.id !== '5bbb5bfb51160a285c000003'
            "
          >
            {{ $t("event.text") }}
          </div>
          <v-row class="mt-2">
            <v-col cols="12" md="4" class="d-md-flex">
              <v-text-field
                dense
                outlined
                :label="$t('event.raceNumber')"
                v-model="searchValue"
                hide-details
                @keyup.enter="search()"
              />
              <div class="mt-2 ml-4">
                {{ $t("event.or") }}
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                dense
                outlined
                hide-details
                clearable
                :items="searches"
                item-text="value"
                item-value="code"
                @change="search"
                :label="$t('event.options')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-btn color="primary" @click="search()" :loading="loadingSearch">
                <v-icon left>fa-search</v-icon>
                {{ $t("event.search") }}
              </v-btn>
              <v-btn
                class="ml-2"
                :disabled="loadingSearch"
                @click="
                  () => {
                    showPrivacy = true;
                  }
                "
              >
                <v-icon left> fa-user-circle</v-icon>
                Privacy
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="!event.active" class="shadow">
        <v-card-text>
          {{ $t("home.offline") }}
        </v-card-text>
      </v-card>
      <v-card v-if="event.password && !allowed" class="shadow">
        <v-card-title>
          <v-row>
            <v-col cols="12" md="10">
              <v-text-field
                dense
                outlined
                hide-details
                :label="$t('event.password')"
                v-model="password"
                @keyup.enter="login()"
                type="password"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-btn color="primary" @click="login()">Login</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card
        class="mt-3 shadow"
        v-if="flat && flats.length > 0 && !loadingSearch && !loadingPics"
      >
        <v-card-text>
          <div v-for="article of flats" :key="article.id">
            {{ article.name }} -
            {{
              article.type === "USB" ? $t(`event.flatUSB`) : $t(`event.flat`)
            }}
            {{ article.price | currency }}
            <v-btn @click="add(article)" color="blue darken-3" icon>
              <v-icon small>fa-shopping-cart</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-3 shadow" v-if="noPics && !loadingSearch">
        <v-card-text class="text-center">{{ $t("event.no") }}</v-card-text>
      </v-card>
      <v-card class="mt-3 shadow" v-if="error && !loadingSearch">
        <v-card-text class="error">{{ $t("event.error") }}</v-card-text>
      </v-card>
      <v-card class="mt-3 shadow" v-if="privacy && !loadingSearch">
        <v-card-text class="text-xs-center">{{
          $t("event.privacy")
        }}</v-card-text>
      </v-card>
      <div class="text-center mt-6">
        <v-progress-circular
          v-if="loadingPics"
          :size="50"
          color="primary"
          indeterminate
        />
      </div>
      <v-row v-if="pics && pics.length > 0" class="mt-4">
        <Article
          v-for="(pic, index) in currentPics"
          :key="index"
          :pic="pic"
          :eventId="event.id"
          :articles="articles.filter((e) => !e.flat)"
        />
      </v-row>
      <div
        class="text-xs-center mt-4 mb-4"
        v-if="!loadingSearch && !loadingPics && pages > 1"
      >
        <v-pagination
          :length="pages"
          :total-visible="10"
          v-model="page"
          circle
        />
      </div>
    </div>
    <Privacy
      :eventId="event.id"
      :show="showPrivacy"
      :close="
        () => {
          showPrivacy = false;
        }
      "
    />
    <ToCart
      :show="showToCart"
      :close="
        () => {
          showToCart = false;
        }
      "
    />
  </v-container>
</template>
<script>
import moment from "moment";
import { store } from "@/stores";
import Privacy from "@/components/Privacy";
import Article from "@/components/Article";
import ToCart from "@/components/ToCart";

export default {
  name: "Event",
  components: { Privacy, Article, ToCart },
  data: () => {
    return {
      showPrivacy: false,
      showToCart: false,
      loading: true,
      loadingSearch: false,
      loadingPics: false,
      password: "",
      searchValue: "",
      allowed: false,
      flat: false,
      error: false,
      privacy: false,
      noPics: false,
      event: {},
      pics: null,
      currentPics: [],
      page: 1,
    };
  },
  created() {
    store
      .getEvent(this.$route.params.id)
      .then((data) => {
        this.event = data;
        this.loading = false;
        if (!this.event.password) {
          this.allowed = true;
          if (this.event.type === `Gallery`) {
            this.search(`a`);
          }
          if (this.$route.params.number) {
            this.searchValue = this.$route.params.number;
            this.search();
          }
        } else {
          this.allowed = false;
        }
      })
      .catch(() => {
        this.$router.push({ path: `/events` });
      });
  },
  computed: {
    searches() {
      const searches = [];
      if (this.event) {
        for (const key of this.event.searches) {
          const search = store.state.searches.find((e) => e.id === key);
          if (search) searches.push(search);
        }
      }
      return searches;
    },
    articles() {
      const articles = [];
      if (this.event) {
        for (const key of this.event.articles) {
          const article = store.state.articles.find((e) => e.id === key);
          if (article) articles.push(article);
        }
      }
      return articles.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    },
    flats() {
      const flats = [];
      if (this.event) {
        for (const key of this.event.articles) {
          const article = store.state.articles.find((e) => e.id === key);
          if (article && article.flat) flats.push(article);
        }
      }
      return flats;
    },
    pages() {
      return Math.round((this.pics || []).length / 30);
    },
  },
  watch: {
    page() {
      window.scrollTo(0, 0);
      this.loadPics();
    },
  },
  methods: {
    moment,
    login() {
      if (this.event.password === this.password) {
        this.allowed = true;
        if (this.event.type === `Gallery`) {
          this.search(`a`);
        }
      }
    },
    search(searchSelection) {
      let value = this.searchValue;
      if (searchSelection) {
        value = searchSelection;
        this.searchValue = "";
      }
      if (value) {
        if (searchSelection) {
          this.searchValue = ``;
        }
        this.loadingSearch = true;
        store
          .getPics(this.event.id, value)
          .then((pics) => {
            this.flat = false;
            if (
              this.searchValue !== `a` &&
              this.searchValue !== `s` &&
              !searchSelection &&
              pics.length > 0
            ) {
              this.flat = true;
            }
            this.pics = pics;
            this.page = 1;
            this.loadingSearch = false;
            this.loadPics();
          })
          .catch((e) => {
            this.error = false;
            this.noPics = false;
            this.loadingSearch = false;
            if (e.response.status === 403) this.noPics = true;
            else this.error = true;
          });
      }
    },
    async loadPics() {
      this.loadingPics = true;
      this.currentPics = [];
      const loadImg = (name) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            let orientation = "portrait";
            if (img.naturalWidth > img.naturalHeight) orientation = "landscape";
            resolve({
              name,
              url: img,
              orientation,
            });
          };
          img.onerror = () => {
            resolve();
          };
          img.src = `https://go4it-thumb.s3-eu-west-1.amazonaws.com/${this.event.id}/${name}`;
        });
      };
      const loadImages = [];
      const total =
        this.pics.length < this.page * 30 ? this.pics.length : this.page * 30;
      for (let i = (this.page - 1) * 30; i < total; i += 1) {
        loadImages.push(loadImg(this.pics[i]));
      }
      let images = await Promise.all(loadImages);
      images = images.sort((a, b) => {
        if (a.orientation > b.orientation) return -1;
        if (a.orientation < b.orientation) return 1;
        return 0;
      });
      this.currentPics = images;
      this.loadingPics = false;
    },
    add(article) {
      this.showToCart = true;
      const pics = [];
      this.currentPics.forEach((pic) => {
        pics.push(pic.name);
      });
      store.addItem({
        eventId: this.event.id,
        article,
        quantity: 1,
        pics,
      });
    },
  },
};
</script>