<template>
  <v-container>
    <v-progress-circular
      v-if="loading"
      :size="50"
      color="primary"
      class="center"
      indeterminate
    />
    <v-row v-if="!loading" class="mt-2 mb-2">
      <v-col cols="12" md="4" v-for="(file, index) in files" :key="index">
        <v-card>
          <v-img :src="`${url}/${file}`" v-if="file !== 'Rechnung.pdf'" />
          <v-card-text v-if="file === 'Rechnung.pdf'" class="text-center">
            <v-icon color="red" x-large>fa-file-pdf</v-icon>
          </v-card-text>
          <v-card-text class="text-center">
            {{ file }}
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-4">
            <v-btn
              :href="`${url}/${file}`"
              class="pa-4"
              block
              target="_blank"
              download
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card v-if="error || errorNoExists" class="shadow">
      <v-card-title>
        {{ $t("errorHeader") }}
      </v-card-title>
      <v-divider />
      <v-card-text v-if="error">
        {{ $t("error") }}
      </v-card-text>
      <v-card-text v-if="errorNoExists">
        {{ $t("errorNoUploaded") }}
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";

export default {
  name: "Download",
  data: () => {
    return {
      url: null,
      loading: true,
      errorNoExists: false,
      error: false,
      files: [],
    };
  },
  created() {
    this.url = `https://go4it-downloads.s3-eu-west-1.amazonaws.com/${this.$route.params.customerId}/${this.$route.params.orderId}`;
    axios
      .get(this.url)
      .then((files) => {
        this.loading = false;
        this.files = files.data;
      })
      .catch((e) => {
        this.loading = false;
        if (e.response.status === 403) this.errorNoExists = true;
        else this.error = true;
      });
  },
};
</script>