import Vue from "vue";
import axios from "axios";
import moment from "moment";

const url = "https://api.go4it-foto.de";
const backupUrl =
  "https://6vcfkatx4oextkphhdoc67h4x40rqieb.lambda-url.eu-west-1.on.aws";

const store = {
  state: Vue.observable({
    ready: false,
    stripe: Stripe("pk_live_OCjkrfChxd8NdHvMtDMMXwns"),
    clientSecret: null,
    searches: [],
    articles: [],
    events: [],
    items: JSON.parse(sessionStorage.getItem(`items`)) || [],
    message: {
      text: "",
      type: "success",
      visible: false,
    },
  }),
  showMessage(type, message) {
    store.state.message = {
      visible: true,
      text: message,
      type,
    };
    setTimeout(() => {
      store.state.message.visible = false;
    }, 2000);
  },
  privacy(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url}/privacy`, data)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          if (e.response.status === 429) {
            axios
              .post(`${backupUrl}/privacy`, data)
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          } else {
            reject();
          }
        });
    });
  },
  notify(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url}/notify`, data)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          if (e.response.status === 429) {
            axios
              .post(`${backupUrl}/notify`, data)
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          } else {
            reject();
          }
        });
    });
  },
  getEvent(id) {
    return axios
      .get(`https://go4it-cache.s3-eu-west-1.amazonaws.com/events/${id}`)
      .then((e) => e.data);
  },
  getPics(eventId, number) {
    return axios
      .get(
        `https://go4it-cache.s3-eu-west-1.amazonaws.com/events/${eventId}/searches/${number}`
      )
      .then((e) => e.data);
  },
  saveOrder(type, customer) {
    sessionStorage.setItem("type", type);
    sessionStorage.setItem("customer", JSON.stringify(customer));
  },
  createOrder() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const items = JSON.parse(sessionStorage.getItem("items"));
      const customer = JSON.parse(sessionStorage.getItem("customer"));
      const type = sessionStorage.getItem("type");
      const data = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        street: customer.street + customer.number,
        zipcode: customer.zipcode,
        city: customer.city,
        country: customer.country,
        paymentIntent: "",
        paymentType: type,
        date: new Date().toJSON(),
        items: [],
      };
      for (const item of items) {
        data.items.push({
          eventId: item.eventId,
          articleId: item.article.id,
          quantity: item.quantity,
          pics: item.pics.toString(),
        });
      }
      if (type === "Stripe") {
        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
        if (!clientSecret) {
          return reject();
        }
        const { paymentIntent } =
          await store.state.stripe.retrievePaymentIntent(clientSecret);
        if (
          paymentIntent.status !== "succeeded" &&
          paymentIntent.status !== "processing" &&
          paymentIntent.status !== "pending"
        )
          return reject();
        data.paymentIntent = paymentIntent.id;
      } else {
        data.paymentIntent = sessionStorage.getItem("paymentIntent") || "";
      }
      await axios
        .post(`${url}/orders`, data)
        .then(() => {
          resolve();
        })
        .catch(async (e) => {
          if (e.response.status === 429) {
            await axios
              .post(`${backupUrl}/orders`, data)
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          } else {
            reject();
          }
        });
    });
  },
  total() {
    return (
      store.state.items.reduce((total, item) => {
        return total + item.article.price * item.quantity;
      }, 0) + this.shippingCost()
    );
  },
  shippingCost() {
    return store.state.items.reduce((total, item) => {
      const shippingCost = parseFloat(item.article.shippingCost);
      if (total < shippingCost) {
        total = shippingCost;
      }
      return total;
    }, 0);
  },
  async getStripeSecret() {
    if (this.total() > 0) {
      const { clientSecret } = await axios
        .post(`${url}/stripe`, {
          total: Math.round(this.total() * 100),
        })
        .then((e) => e.data);
      store.state.clientSecret = clientSecret;
    }
    return;
  },
  addItem(item) {
    this.state.items.push(item);
    sessionStorage.setItem(`items`, JSON.stringify(this.state.items));
  },
  updateItemQuantity(index, value) {
    this.state.items[index].quantity = value;
    sessionStorage.setItem(`items`, JSON.stringify(this.state.items));
  },
  cleanPay() {
    sessionStorage.removeItem("customer");
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("paymentIntent");
  },
  clearItems() {
    this.state.items = [];
    sessionStorage.setItem(`items`, JSON.stringify(this.state.items));
    this.cleanPay();
  },
  removeItem(index) {
    this.state.items.splice(index, 1);
    sessionStorage.setItem(`items`, JSON.stringify(this.state.items));
  },
};
const init = async (router) => {
  store.router = router;
  store.state.events = await axios
    .get("https://go4it-cache.s3-eu-west-1.amazonaws.com/events")
    .then((e) => e.data);
  for (const event of store.state.events) {
    event.name = event.name
      .replace(moment(event.date).format("YYYY"), "")
      .trim();
    event.text = `${event.name} | ${moment(event.date).format("DD.MM.YYYY")}`;
  }

  store.state.articles = await axios
    .get("https://go4it-cache.s3-eu-west-1.amazonaws.com/articles")
    .then((e) => e.data);
  for (const article of store.state.articles) {
    article.text = `${article.name} (${article.price.toFixed(2)}€)`;
  }

  store.state.searches = await axios
    .get("https://go4it-cache.s3-eu-west-1.amazonaws.com/searches")
    .then((e) => e.data);
  store.state.ready = true;
};
export { init, store };
