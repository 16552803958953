<template>
  <v-dialog v-model="show" max-width="420">
    <v-card>
      <v-toolbar elevation="0">
        <v-toolbar-title> {{ $t("added") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="() => close()">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text class="pt-4">
        {{ $t("event.added") }}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          @click="
            () => {
              close();
            }
          "
        >
          {{ $t("event.close") }}
        </v-btn>
        <v-btn text color="success" to="/cart">{{ $t("event.to") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: Boolean,
    close: Function,
  },
};
</script>