import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/views/Home';
import Event from '@/views/Event';
import Cart from '@/views/Cart';
import Privacy from '@/views/Privacy';
import Download from '@/views/Download';
import Imprint from '@/views/Imprint';
import Terms from '@/views/Terms';

Vue.use(Router);

const router = new Router({
  mode: `history`,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [{
    path: `*`,
    component: Home,
  },
  {
    path: `/`,
    component: Home,
  },
  {
    path: `/cart`,
    component: Cart,
  },
  {
    path: `/cart/payed`,
    component: Cart,
  },
  {
    path: `/privacy`,
    component: Privacy,
  },
  {
    path: `/imprint`,
    component: Imprint,
  },
  {
    path: `/terms`,
    component: Terms,
  },
  {
    path: `/event/:id/:number?`,
    component: Event,
  },
  {
    path: `/events/:id/:number?`,
    component: Event,
  },
  {
    path: `/:customerId/:orderId`,
    component: Download,
  },
  ],
});

export default router;
