<template>
  <v-app-bar app elevation="0">
    <v-container>
      <v-row>
        <v-col cols="6">
          <router-link to="/">
            <img :src="require('@/assets/logo.png')" style="height: 40px" />
          </router-link>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <div class="mt-3">{{ lang }}</div>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="black">fa-globe-europe</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="changeLocale('en')">
                <v-list-item-title> EN </v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeLocale('de')">
                <v-list-item-title> DE </v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeLocale('fr')">
                <v-list-item-title> FR </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon color="blue darken-3" class="mr-1" to="/cart">
            <v-badge>
              <span slot="badge">{{ total }}</span>
              <v-icon>fa-shopping-cart</v-icon>
            </v-badge>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
import { store } from "@/stores";
export default {
  name: "NavBar",
  computed: {
    total() {
      return store.state.items.length;
    },
    lang() {
      return this.$i18n.locale.toUpperCase();
    },
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style>
.v-sheet.v-app-bar.v-toolbar {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%) !important;
}
</style>
