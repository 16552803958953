<template>
  <v-app v-cloak>
    <navbar />
    <v-alert
      class="main-alert"
      :type="message.type"
      dismissible
      v-model="message.visible"
      v-if="message.visible"
    >
      {{ message.text }}
    </v-alert>
    <v-main class="blue-grey lighten-5">
      <v-progress-circular
        v-if="!ready"
        :size="50"
        color="primary"
        class="center"
        indeterminate
      />
      <router-view v-if="ready" />
    </v-main>
    <nav-footer />
  </v-app>
</template>

<script>
import { store } from "./stores";
import Navbar from "@/components/Navbar";
import NavFooter from "@/components/Footer";

export default {
  name: "App",
  components: { Navbar, NavFooter },
  computed: {
    ready() {
      return store.state.ready;
    },
    message() {
      return store.state.message;
    },
  },
};
</script>
<style>
.shadow {
  box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%) !important;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
<style scoped>
.main-alert {
  z-index: 1000;
  position: fixed;
  top: 20px;
  right: 20px;
}
</style>