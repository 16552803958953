<template>
  <v-col cols="12" sm="6" md="3" v-if="pic">
    <v-card class="shadow">
      <v-img :src="pic.url" @contextmenu.prevent="error" />
      <div class="text-center mt-2">
        {{ pic.name }}
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-select
              dense
              outlined
              :items="articles"
              v-model="article"
              item-text="text"
              return-object
              :label="$t('attributes.article')"
              :error="$v.article.$error"
              :error-messages="articelError"
              :hide-details="!articelError"
            />
          </v-col>
          <v-col cols="3">
            <v-btn @click="add()" color="blue darken-3" icon>
              <v-icon small>fa-shopping-cart</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ToCart
      :show="dialog"
      :close="
        () => {
          dialog = false;
        }
      "
    />
  </v-col>
</template>
<script>
import { store } from "@/stores";
import { required } from "vuelidate/lib/validators";
import ToCart from "@/components/ToCart";

export default {
  name: "Article",
  props: ["pic", "eventId", "articles"],
  components: { ToCart },
  data: () => {
    return {
      dialog: false,
      article: null,
      base64: null,
    };
  },
  created() {},
  validations: {
    article: {
      required,
    },
  },
  computed: {
    articelError() {
      if (!this.$v.article.$dirty) return null;
      if (!this.$v.article.required) return this.$t("messages.articleRequired");
      return null;
    },
  },
  methods: {
    add() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dialog = true;

        const pics = [];
        pics.push(this.$props.pic.name);
        store.addItem({
          eventId: this.$props.eventId,
          article: this.article,
          quantity: 1,
          pics,
        });
      }
    },
    error() {
      store.showMessage("error", "This is not allowed!");
    },
  },
};
</script>