<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-card elevation="1">
          <v-card-title>{{ $t("cart.contact") }}</v-card-title>
          <v-divider />
          <v-card-text>
            <v-text-field
              outlined
              dense
              v-model="customer.firstName"
              :label="$t('attributes.firstname')"
              :error="$v.customer.firstName.$error"
              :error-messages="firstNameError"
            />
            <v-text-field
              outlined
              dense
              v-model="customer.lastName"
              :label="$t('attributes.lastname')"
              :error="$v.customer.lastName.$error"
              :error-messages="lastNameError"
            />
            <v-text-field
              outlined
              dense
              v-model="customer.email"
              :label="$t('attributes.mail')"
              :error="$v.customer.email.$error"
              :error-messages="emailError"
            />
            <div v-if="shippingCost > 0">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    outlined
                    dense
                    v-model="customer.street"
                    :label="$t('attributes.street')"
                    :error="$v.customer.street.$error"
                    :error-messages="streetError"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    dense
                    v-model="customer.number"
                    :label="$t('attributes.number')"
                    :error="$v.customer.number.$error"
                    :error-messages="numberError"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    outlined
                    dense
                    v-model="customer.zipcode"
                    :label="$t('attributes.zipcode')"
                    :error="$v.customer.zipcode.$error"
                    :error-messages="zipcodeError"
                  />
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    outlined
                    dense
                    v-model="customer.city"
                    :label="$t('attributes.city')"
                    :error="$v.customer.city.$error"
                    :error-messages="cityError"
                  />
                </v-col>
              </v-row>
              <v-select
                outlined
                dense
                :label="$t('attributes.country')"
                v-model="customer.country"
                :items="countries"
                hide-details
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>{{ $t("cart.payType") }} </v-card-title>
          <v-divider />
          <v-card-text v-show="total === 0">{{ $t("cart.free") }}</v-card-text>
          <v-card-text v-show="total !== 0" id="payment-element" />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" md="3">
        <v-btn block @click="back()">
          {{ $t("cart.back") }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="d-flex justify-md-end justify-center"
        v-if="total > 0"
      >
        <div id="paypal-button-container" />
        <div class="ml-2 mr-2 mt-1">{{ $t("event.or") }}</div>
        <v-btn color="primary" :loading="loading" @click="order">
          {{ $t("cart.buy") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" offset-md="6" v-if="total === 0">
        <v-btn block color="primary" :loading="loading" @click="order">
          {{ $t("cart.buy") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { store } from "@/stores";
import { required, requiredIf, email } from "vuelidate/lib/validators";

export default {
  name: `CartPay`,
  props: ["next", "back"],
  data() {
    return {
      loading: false,
      countries: [
        `Deutschland`,
        `Schweiz`,
        `Österreich`,
        `Frankreich`,
        `Luxemburg`,
        `Italien`,
        `Niederlande`,
        `Polen`,
        `Belgien`,
        `Tschechien`,
      ],
      elements: null,
      customer: {
        firstName: "",
        lastName: "",
        email: "",
        street: "",
        zipcode: "",
        city: "",
        country: "Deutschland",
      },
      type: `Credit`,
    };
  },
  mounted() {
    if (store.total() > 0) {
      this.elements = store.state.stripe.elements({
        appearance: {
          theme: "stripe",
        },
        clientSecret: store.state.clientSecret,
      });
      this.elements.create("payment").mount("#payment-element");
      this.payPal();
    }
  },
  validations() {
    return {
      customer: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          email,
          required,
        },
        city: {
          required: requiredIf(() => this.shippingCost > 0),
        },
        zipcode: {
          required: requiredIf(() => this.shippingCost > 0),
        },
        street: {
          required: requiredIf(() => this.shippingCost > 0),
        },
        number: {
          required: requiredIf(() => this.shippingCost > 0),
        },
      },
    };
  },
  computed: {
    total() {
      return store.total();
    },
    shippingCost() {
      return store.shippingCost();
    },
    firstNameError() {
      if (!this.$v.customer.firstName.$dirty) return null;
      if (!this.$v.customer.firstName.required)
        return this.$t("messages.fristNameRequired");
      return null;
    },
    lastNameError() {
      if (!this.$v.customer.lastName.$dirty) return null;
      if (!this.$v.customer.lastName.required)
        return this.$t("messages.lastNameRequired");
      return null;
    },
    streetError() {
      if (!this.$v.customer.street.$dirty) return null;
      if (!this.$v.customer.street.required)
        return this.$t("messages.streetRequired");
      return null;
    },
    numberError() {
      if (!this.$v.customer.number.$dirty) return null;
      if (!this.$v.customer.number.required)
        return this.$t("messages.numberRequired");
      return null;
    },
    cityError() {
      if (!this.$v.customer.city.$dirty) return null;
      if (!this.$v.customer.city.required)
        return this.$t("messages.cityRequired");
      return null;
    },
    zipcodeError() {
      if (!this.$v.customer.zipcode.$dirty) return null;
      if (!this.$v.customer.zipcode.required)
        return this.$t("messages.zipCodeRequired");
      return null;
    },
    emailError() {
      if (!this.$v.customer.email.$dirty) return null;
      if (!this.$v.customer.email.required)
        return this.$t("messages.emailRequired");
      if (!this.$v.customer.email.email)
        return this.$t("messages.emailNotValid");
      return null;
    },
  },
  methods: {
    payPal() {
      const me = this;
      // eslint-disable-next-line no-undef
      paypal
        .Buttons({
          style: {
            height: 36,
            color: `gold`,
            label: `pay`,
            tagline: false,
          },
          onClick(data, actions) {
            me.$v.$touch();
            if (!me.$v.$invalid) {
              me.loading = true;
              return actions.resolve();
            } else {
              return actions.reject();
            }
          },
          createOrder(data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: me.total,
                  },
                },
              ],
            });
          },
          onApprove(data, actions) {
            // eslint-disable-next-line no-debugger
            debugger;
            sessionStorage.setItem("paymentIntent", data.orderID);
            return actions.order.capture().then(() => {
              store.saveOrder("PayPal", me.customer);
              me.$props.next();
            });
          },
          onCancel() {
            me.loading = false;
          },
          onError() {
            store.showMessage("error", me.$t(`pay_error`));
            me.loading = false;
          },
        })
        .render(`#paypal-button-container`);
    },
    async order() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.total > 0) {
          this.loading = true;
          store.saveOrder("Stripe", this.customer);
          await store.state.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
              return_url: `${location.origin}/cart/payed`,
            },
          });
          this.loading = false;
        } else {
          store.saveOrder("PayPal", this.customer);
          this.$props.next();
        }
      }
    },
  },
};
</script>
