var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-carousel',{staticClass:"hidden-xs-only mt-4",attrs:{"cycle":"","height":"280"}},[_c('v-carousel-item',{attrs:{"src":require('@/assets/img1.jpg')}}),_c('v-carousel-item',{attrs:{"src":require('@/assets/img2.jpg')}}),_c('v-carousel-item',{attrs:{"src":require('@/assets/img3.jpg')}})],1),_c('v-row',{staticClass:"mt-4"},_vm._l((_vm.top4),function(event){return _c('v-col',{key:event.id,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('h5',[_vm._v(_vm._s(event.name))])]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.moment(event.date).format("DD.MM.YYYY"))+" ")]),_c('v-card-text',[(!event.active)?_c('div',[_vm._v(_vm._s(_vm.$t("home.offline")))]):_vm._e(),(event.active)?_c('div',[_vm._v(_vm._s(_vm.$t("home.online")))]):_vm._e()]),_c('v-card-actions',[(!event.active)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () {
                _vm.showNotify = true;
                _vm.eventId = event.id;
              }}},[_c('v-icon',{attrs:{"right":""}},[_vm._v("far fa-envelope")]),_vm._v(" Notify ")],1):_vm._e(),(event.active)?_c('v-btn',{attrs:{"text":"","color":"success","to":'events/' + event.id}},[_vm._v(" "+_vm._s(_vm.$t("home.toEvent"))+" "),_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa-chevron-right ")])],1):_vm._e()],1)],1)],1)}),1),_c('v-card',{staticClass:"shadow mt-6 mb-4"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("home.moreEvents"))+": ")]),_c('v-card-text',[_c('v-combobox',{attrs:{"outlined":"","dense":"","items":_vm.events,"item-value":"id"},on:{"change":function (item) {
            _vm.$router.push({ path: ("/events/" + (item.id)) });
          }}})],1)],1),_c('privacy',{attrs:{"eventId":_vm.eventId,"show":_vm.showPrivacy,"close":function () {
        _vm.showPrivacy = false;
      }}}),_c('notify',{attrs:{"eventId":_vm.eventId,"show":_vm.showNotify,"close":function () {
        _vm.showNotify = false;
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }