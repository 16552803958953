<template>
  <div>
    <table class="table table-bordered">
      <tbody>
        <tr class="backcolor">
          <td class="backcolor" width="50%">
            <b>{{ $t("cart.item") }}</b>
          </td>
          <td class="backcolor" width="35%">
            <b>{{ $t("cart.amount") }}</b>
          </td>
          <td class="backcolor" width="15%">
            <b>{{ $t("cart.price") }}</b>
          </td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="3" style="text-align: center">{{ $t("cart.empty") }}</td>
        </tr>
        <cart-list-item
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :index="index"
        ></cart-list-item>
        <tr class="backcolor">
          <td class="backcolor" style="height: 15px" colspan="4"></td>
        </tr>
        <tr>
          <td align="right" colspan="2">
            <b class="ng-scope">{{ $t("cart.vat") }}:</b>
          </td>
          <td align="right">
            <b>{{ (total - total / 1.19) | currency }}</b>
          </td>
        </tr>
        <tr>
          <td align="right" colspan="2">
            <b class="ng-scope">{{ $t("cart.shipfees") }}:</b>
          </td>
          <td align="right">
            <b>{{ shippingCost | currency }}</b>
          </td>
        </tr>
        <tr class="backcolor">
          <td class="backcolor" align="right" colspan="2">
            <b class="ng-scope">{{ $t("cart.total") }}:</b>
          </td>
          <td class="backcolor" align="right">
            <b>{{ total | currency }}</b>
          </td>
        </tr>
      </tbody>
    </table>
    <v-row class="mt-3 mb-1">
      <v-col cols="12" md="3">
        <v-btn block color="error" @click="clear()" :disabled="loading">{{
          $t("cart.clear")
        }}</v-btn>
      </v-col>
      <v-col cols="12" md="3" offset-md="6">
        <v-btn
          block
          color="primary"
          :disabled="items.length === 0"
          :loading="loading"
          @click="
            () => {
              checkout();
            }
          "
        >
          {{ $t("cart.toPay") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { store } from "@/stores";
import CartListItem from "@/components/CartListItem";

export default {
  name: "CartList",
  props: ["next"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    items() {
      return store.state.items;
    },
    total() {
      return store.total();
    },
    shippingCost() {
      return store.shippingCost();
    },
  },
  methods: {
    clear() {
      store.clearItems();
    },
    checkout() {
      this.loading = true;
      store
        .getStripeSecret()
        .then(() => {
          this.$props.next();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: {
    CartListItem,
  },
};
</script>

<style>
@media (max-width: 530px) {
  .quantity .row {
    width: 50px;
  }
}

table.table {
  border-radius: 2px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.table tbody td {
  font-weight: 400;
  font-size: 13px;
}

table.table tbody td,
table.table tbody th {
  height: 48px;
}

table.table tbody td:first-child,
table.table tbody td:not(:first-child),
table.table tbody th:first-child,
table.table tbody th:not(:first-child),
table.table thead td:first-child,
table.table thead td:not(:first-child),
table.table thead th:first-child,
table.table thead th:not(:first-child) {
  padding: 0 24px;
}

.quantity button {
  margin-top: 16px;
}
.backcolor {
  background-color: #f5f5f5;
}
.quantity input {
  text-align: center;
}
.text {
  word-wrap: break-word;
}
</style>