<template>
  <v-container class="mt-4">
    <v-stepper v-model="currentStep" class="shadow">
      <v-stepper-header class="header">
        <v-stepper-step step="1" :complete="currentStep > 1">
          {{ $t("cart.name") }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="2" :complete="currentStep > 2">
          {{ $t("cart.pay") }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="3">{{ $t("cart.completion") }}</v-stepper-step>
      </v-stepper-header>
      <v-divider />
      <v-stepper-items>
        <v-stepper-content step="1">
          <CartList :next="next" />
        </v-stepper-content>
        <v-stepper-content step="2">
          <CartPay :next="next" :back="back" v-if="currentStep === 2" />
        </v-stepper-content>
        <v-stepper-content step="3">
          <CartPayed v-if="currentStep === 3" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
<script>
import CartPay from "@/components/CartPay";
import CartList from "@/components/CartList";
import CartPayed from "@/components/CartPayed";

export default {
  name: `Cart`,
  components: { CartList, CartPay, CartPayed },
  data() {
    return {
      step: 1,
    };
  },
  computed: {
    currentStep() {
      return this.$route.path === `/cart/payed` ? 3 : this.step;
    },
  },
  methods: {
    next() {
      this.step += 1;
    },
    back() {
      this.step -= 1;
    },
  },
};
</script>
<style scoped>
.header {
  box-shadow: none;
}
</style>