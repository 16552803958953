<template>
  <v-card>
    <v-card-title primary-title v-if="!loading && !error">
      <span class="headline">{{ $t("cart.payedText1") }}</span>
    </v-card-title>
    <v-card-text v-if="!loading && !error">
      {{ $t("cart.payedText2") }}<br /><br />
      {{ $t("cart.payedText3") }}
    </v-card-text>
    <v-card-title v-if="!loading && error">
      {{ $t("error") }}
    </v-card-title>
    <v-card-text class="text-center" v-if="loading">
      <v-progress-circular :size="50" color="primary" indeterminate />
      <br /><br />
      {{ $t("processed") }}
    </v-card-text>
  </v-card>
</template>
<script>
import { store } from "@/stores";

export default {
  name: `CartPayed`,
  data() {
    return {
      loading: true,
      error: false,
    };
  },
  mounted() {
    this.loading = true;
    store
      .createOrder()
      .then(() => {
        this.loading = false;
        store.clearItems();
      })
      .catch(() => {
        store.cleanPay();
        this.loading = false;
        this.error = true;
      });
  },
};
</script>