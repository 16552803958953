<template>
  <v-dialog v-model="localShow" persistent max-width="600">
    <v-card>
      <v-toolbar elevation="0">
        <v-toolbar-title>{{ $t("reminder.header") }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="
            () => {
              close();
            }
          "
          :disabled="loading"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text class="pt-6">
        <v-text-field
          outlined
          dense
          v-model.lazy="email"
          type="email"
          placeholder="test@test.de"
          :label="$t('attributes.mail')"
          :error="$v.email.$error"
          :error-messages="emailErrors"
        />
        <v-text-field
          outlined
          dense
          v-model.lazy="racenumber"
          placeholder="1234"
          :label="$t('event.raceNumber')"
          :error="$v.racenumber.$error"
          :error-messages="racenumberErrors"
        />
        <v-checkbox
          class="mt-0"
          :label="$t('reminder.label')"
          v-model.lazy="accept"
          :error="$v.accept.$error"
          :error-messages="acceptErrors"
          :hide-details="!acceptErrors"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="
            () => {
              close();
            }
          "
          :disabled="loading"
        >
          {{ $t("reminder.close") }}
        </v-btn>
        <v-btn text color="primary" @click="notify" :loading="loading">
          {{ $t("reminder.register") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { store } from "@/stores";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Privacy",
  props: ["eventId", "show", "close"],
  data: () => {
    return {
      accept: false,
      racenumber: "",
      email: "",
      loading: false,
      localShow: false,
    };
  },
  validations: {
    racenumber: {
      required,
    },
    accept: {
      checked(val) {
        return val;
      },
    },
    email: {
      required,
      email,
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push(this.$t("messages.emailRequired"));
      !this.$v.email.email && errors.push(this.$t("messages.emailNotValid"));
      return errors;
    },
    racenumberErrors() {
      const errors = [];
      if (!this.$v.racenumber.$dirty) return errors;
      !this.$v.racenumber.required &&
        errors.push(this.$t("messages.racenumberRequired"));
      return errors;
    },
    acceptErrors() {
      const errors = [];
      if (!this.$v.accept.$dirty) return errors;
      !this.$v.accept.checked && errors.push(this.$t("messages.accept"));
      return errors;
    },
  },
  methods: {
    notify() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        store
          .notify({
            eventId: this.$props.eventId,
            racenumber: this.racenumber,
            email: this.email,
          })
          .then(() => {
            store.showMessage("success", this.$t("reminder_added"));
            this.loading = false;
            this.$props.close();
          })
          .catch(() => {
            store.showMessage("error", this.$t("error"));
            this.loading = false;
          });
      }
    },
  },
  watch: {
    show(value) {
      this.localShow = value;
      if (value) {
        this.email = "";
        this.racenumber = "";
        this.accept = false;
      }
    },
  },
};
</script>