import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import { init } from "./stores"
import i18n from './i18n'

import "@fontsource/roboto/100.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/900.css"

init(router);
Vue.config.productionTip = true
Vue.use(Vuelidate)
Vue.filter(`currency`, value => {
  return `${parseFloat(value).toFixed(2)}€`;
});

new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
