import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
const messages = {
  de: {
    errorHeader: "Fehler",
    errorNoUploaded:
      "Bitte schreiben Sie eine E-Mail an support@go4it-foto damit Ihre Bilder noch einmal zur verfügung gestellt werden. Die Daten stehen nur 30 Tage zum Download bereit.",
    attributes: {
      firstname: `Vorname`,
      lastname: `Nachname`,
      mail: `E-Mail`,
      street: `Straße`,
      number: `Hausnummer`,
      zipcode: `PLZ`,
      city: `Ort`,
      country: `Land`,
      iban: `IBAN`,
      mandat: `Mandat`,
      racenumber: `Startnummer`,
      article: `Artikel`,
    },
    cart: {
      name: `Warenkorb`,
      pay: `Adresse / Zahlung`,
      completion: `Abschluss`,
      clear: `Warenkorb löschen`,
      toPay: `Zur Kasse Gehen`,
      item: `Artikel`,
      amount: `Menge`,
      price: `Preis`,
      vat: `enthaltene MwSt. 19%`,
      shipfees: `Versand`,
      total: `Total`,
      empty: `Ihr Warenkorp ist leer.`,
      contact: `Kontaktdaten`,
      payType: `Zahlungsart`,
      free: `Bilder werden kostenlos vom Veranstalter/Sponsoren oder von Go4it-Foto.de zur Verfügung gestellt.`,
      credit: `Kreditkarte`,
      debit: `Lastschrift`,
      ibanText: `Mit der Angabe Ihrer IBAN und der Bestätigung dieser Zahlung ermächtigen Sie go4it-foto und Stripe, unseren Zahlungsdienstleister, Anweisungen an Ihre Bank zu senden, Ihr Konto zu belasten und Ihre Bank, Ihr Konto gemäß diesen Anweisungen zu belasten. Sie haben Anspruch auf eine Rückerstattung von Ihrer Bank gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank. Eine Rückerstattung muss innerhalb von 8 Wochen ab dem Datum der Belastung Ihres Kontos erfolgen.`,
      back: `Zurück`,
      buy: `Bezahlen`,
      or: `Oder geben Sie unten Ihre Kontakt- und Zahlungsdaten ein`,
      payedText1: `Danke für Ihre Bestellung`,
      payedText2: `Sie haben soeben eine E-Mail erhalten mit einer Übersicht der bestellten Artikel.`,
      payedText3: `Das go4it-foto Team bedankt sich für Ihre Bestellung.`,
    },
    home: {
      partner: `Unsere Partner sind:`,
      organizer: `Veranstalter`,
      toEvent: `zur Veranstaltung`,
      moreEvents: `Weitere Veranstaltung`,
      selectEvent: `Bitte wählen Sie ein Veranstaltung aus`,
      offline: `Ihre persönlichen Bilder sind bald online!`,
      online: `Ihre persönlichen Bilder sind jetzt online!`,
    },
    event: {
      text: `Bitte geben Sie Ihre Startnummer ein um Ihre Bilder zu sehen.`,
      raceNumber: `Startnummer`,
      or: `oder`,
      options: `Optionen`,
      search: `Suchen`,
      selectArticle: `Bitte Artikel auswählen`,
      added: `Artikel wurde in den Warenkorb gelegt.`,
      close: `Weiter Einkaufen`,
      to: `Zur Kasse Gehen`,
      no: `Leider wurden keine Bilder gefunden. Wählen Sie vielleicht Optionen aus!`,
      flat: `kaufen Sie alle Bilder einer Startnummer digital zum Download für nur`,
      flatUSB: `kaufen Sie alle Bilder einer Startnummer digital auf einem USB Stick für nur`,
      more: `Mehr Bilder laden`,
      password: `Passwort`,
      error: `Wir haben technische Probleme. Bitte versuchen Sie es später noch einmal oder senden Sie uns bitte eine E-Mail: support@go4it-foto.de`,
      privacy: `Ihre persönlichen Bilder sind aufgrund einer Datenschutzanfrage offline. Sollte dies nicht richtige sein, schreiben Sie uns bitte eine Mail an support@go4it-foto.de`,
    },
    reminder: {
      header: `E-Mail Erinnerung`,
      register: `Eintragen`,
      close: `Zurück`,
      label: `Ich bin damit einverstanden, dass die eingegebenen Daten bei go4it-foto.de gespeichert werden und ich von go4it-foto.de per Email informiert werde, wenn meine Bilder online sind. Es erfolgt keine Weitergabe der Daten an Dritte.`,
    },
    offline: {
      header: `Privatsphäre - Suche für diese Startnummer deaktivieren`,
      request: `Beantragen`,
      label: `Ich bin damit einverstanden, das die Bilder von meiner Startnummer nicht mehr gesucht werden können und somit auch eine spätere Bestellung nicht mehr möglich ist. Es erfolgt keine Weitergabe der Daten an Dritte.`,
    },
    impressum: `Impressum`,
    termsAndConditions: `AGB`,
    privacy: `Datenschutz`,
    cookieText: `Diese Website verwendet Cookies für Funktions-, Komfort- und Statistikzwecke. Wenn Sie auf der Seite weitersurfen, akzeptieren Sie die Cookie-Verwendung.`,
    cookieMore: `Mehr Informationen`,
    cookieAccept: `Einverstanden!`,
    loading: `Daten werden geladen ...`,
    card_declined: `Ihre Karte wurde abgelehnt.`,
    incorrect_cvc: `Der Sicherheitscode Ihrer Karte ist falsch.`,
    expired_card: `Ihre Karte ist abgelaufen.`,
    processing_error: `Beim Verarbeiten Ihrer Karte ist ein Fehler aufgetreten. Versuche es noch einmal.`,
    pay_error: `Bestellung konnte nicht gespeichert werden. Versuchen Sie es bitte später noch einmal.`,
    reminder_added: `Sie haben sich erfolgreich eingetragen und erhalten eine E-Mail sobald die Veranstaltung online ist.`,
    privacy_added: `Beantragen wurde erfolgreich an uns gesendet und wird nach Prüfung ausgeführt!`,
    error:
      "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal!",
    added: `Hinzugefügt`,
    processed:
      "Die Bestellung wird gerade bearbeitet. Bitte schließen Sie das Fenster nicht!",
    messages: {
      emailRequired: "Bitte geben Sie eine E-Mail ein",
      emailNotValid: "Bitte geben Sie eine gültige E-Mail ein",
      fristNameRequired: "Bitte geben Sie einen Vornamen ein",
      lastNameRequired: "Bitte geben Sie einen Nachnamen ein",
      cityRequired: "Bitte geben Sie eine Stadt ein",
      zipCodeRequired: "Bitte geben Sie eine Postleitzahl ein",
      streetRequired: "Bitte geben Sie eine Straße ein",
      numberRequired: "Bitte geben Sie eine Hausnummer ein",
      racenumberRequired: "Bitte geben Sie eine Startnummer ein",
      articleRequired: "Bitte wählen Sie einen Artikel",
      accept: "Bitte akzeptieren",
    },
  },
  en: {
    errorHeader: "Error",
    errorNoUploaded:
      "Please write an e-mail to support@go4it-foto so that your pictures can be made available again. The data is only available for download for 30 days.",
    attributes: {
      firstname: `First Name`,
      lastname: `Last Name`,
      mail: `E-Mail`,
      street: `Street`,
      number: `House number`,
      zipcode: `Zipcode`,
      city: `City`,
      country: `Country`,
      iban: `IBAN`,
      mandat: `Mandate`,
      racenumber: `Race Number`,
      article: `Article`,
    },
    cart: {
      name: `Cart`,
      pay: `Address / Payment`,
      completion: `Completion`,
      clear: `Delete Items`,
      toPay: `Proceed to checkout`,
      item: `Item`,
      amount: `Amount`,
      price: `Price`,
      vat: `VAT of 19% included`,
      shipfees: `Shipping fees`,
      total: `Total`,
      empty: `Your shopping cart is empty.`,
      contact: `Contact Details`,
      payType: `Payment Method`,
      free: `Pictures are provided free of charge by the organizer/sponsors or go4it-foto.de.`,
      credit: `Credit`,
      debit: `Debit`,
      ibanText: `By submitting your IBAN and confirming this payment, you authorize go4it-foto and Stripe, our payment service provider, to send instructions to your bank, debit your account and debit your bank account in accordance with these instructions. You are entitled to a refund from your bank in accordance with the terms of your agreement with your bank. A refund must be made within 8 weeks from the date of debiting your account.`,
      back: `Back`,
      buy: `Buy Now`,
      or: `Or enter your contact and payment details below`,
      payedText1: `Thank you for your order`,
      payedText2: `You have just received an e-mail with an overview of the ordered items.`,
      payedText3: `The go4it-foto team thanks you for your order.`,
    },
    home: {
      partner: `Our partners are:`,
      organizer: `Organizer`,
      toEvent: `to the event`,
      moreEvents: `Further events`,
      selectEvent: `Please select an event`,
      offline: `Your personal pictures will be online soon!`,
      online: `Your personal pictures are now online!`,
    },
    event: {
      text: `Please enter your race number to see your pictures.`,
      raceNumber: `Race Number`,
      or: `or`,
      options: `Options`,
      search: `Search`,
      selectArticle: `Please select article`,
      added: `Article was added to the shopping cart.`,
      close: `Continue shopping`,
      to: `Proceed to checkout`,
      no: `Sorry, no images found. You might want to choose options!`,
      flat: `buy all pictures of a race number digitally for download for only`,
      flatUSB: `buy all pictures of a race number digitally on a USB stick for only`,
      more: `Load more pictures`,
      password: `Password`,
      error: `We have technical problems. Please try again later or send us an e-mail: support@go4it-foto.de`,
      privacy: `Your personal pictures are offline because of a privacy request. If this is not correct please write us a mail support@go4it-foto.de`,
    },
    reminder: {
      header: `Mail Reminder`,
      register: `Register`,
      close: `Back`,
      label: `I agree that the entered data will be stored at go4it-foto.de and I will be informed by go4it-foto.de via email when my pictures are online. The data will not be passed on to third parties.`,
    },
    offline: {
      header: `Privacy - Disable search for this Race Number`,
      request: `Request`,
      label: `I agree with the fact that the pictures of my start number can no longer be searched and therefore also a later order is no longer possible. The data will not be passed on to third parties.`,
    },
    impressum: `Impressum`,
    termsAndConditions: `Terms and Conditions`,
    privacy: `Privacy`,
    cookieText: `This website uses cookies for functional, convenience and statistical purposes. If you continue browsing the site, you accept the use of cookies.`,
    cookieMore: `More information`,
    cookieAccept: `I Accept!`,
    loading: `Loading data ...`,
    card_declined: `Your card has been declined.`,
    incorrect_cvc: `Your card's security code is wrong.`,
    expired_card: `Your card has expired.`,
    processing_error: `An error occurred while processing your card. Try it again.`,
    pay_error: `Order could not be saved. Please try again later.`,
    reminder_added: `You have successfully registered and will receive an e-mail as soon as the event is online.`,
    privacy_added: `Request has been successfully sent to us and will be executed after verification!`,
    error: "Something went wrong, please try again later!",
    added: `Added`,
    processed: "The order is being processed. Please do not close the window!",
    messages: {
      emailRequired: "Please enter an email",
      emailNotValid: "Please enter a valid email",
      fristNameRequired: "Please enter a firstname",
      lastNameRequired: "Please enter a lastname",
      cityRequired: "Please enter a city",
      zipCodeRequired: "Please enter a zipcode",
      streetRequired: "Please enter a street",
      numberRequired: "Please enter a house number",
      racenumberRequired: "Please enter a racenumber",
      articleRequired: "Please select a article",
      accept: "Please accept",
    },
  },
  fr: {
    errorHeader: "Erreur",
    errorNoUploaded:
      "Veuillez envoyer un e-mail à support@go4it-foto pour que vos images soient à nouveau disponibles. Les données ne sont disponibles au téléchargement que pendant 30 jours.",
    attributes: {
      firstname: `Prénom`,
      lastname: `Nom de famille`,
      mail: `Mail`,
      street: `Rue`,
      number: `Numéro de maison`,
      zipcode: `Code postal`,
      city: `Ville`,
      country: `Pays`,
      iban: `IBAN`,
      mandat: `Mandat`,
      racenumber: `Numéro de Course`,
      article: `Article`,
    },
    cart: {
      name: `Chariot`,
      pay: `Adresse / Paiement`,
      completion: `Achèvement`,
      clear: `Supprimer des postes`,
      toPay: `Procéder au paiement`,
      item: `Item`,
      amount: `Montant`,
      price: `Prix`,
      vat: `TVA de 19% incluse`,
      shipfees: `Frais d'expédition`,
      total: `Total`,
      empty: `Votre panier est vide.`,
      contact: `Coordonnées du contact`,
      payType: `Mode de paiement`,
      free: `Les photos sont fournies gratuitement par l'organisateur/les organisateurs ou go4it-foto.de.`,
      credit: `Crédit`,
      debit: `Débit`,
      ibanText: `En soumettant votre IBAN et en confirmant ce paiement, vous autorisez go4it-foto et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque, débiter votre compte et débiter votre compte bancaire conformément à ces instructions. Vous avez droit à un remboursement de la part de votre banque conformément aux termes de votre entente avec votre banque. Un remboursement doit être effectué dans les 8 semaines suivant la date du débit de votre compte.`,
      back: `Dos`,
      buy: `Acheter maintenant`,
      or: `Ou entrez vos coordonnées de contact et de paiement ci-dessous`,
      payedText1: `Merci de votre commande`,
      payedText2: `Vous venez de recevoir un e-mail avec un aperçu des articles commandés.`,
      payedText3: `L'équipe go4it-foto vous remercie de votre commande.`,
    },
    home: {
      partner: `Nos partenaires sont:`,
      organizer: `Organisateur`,
      toEvent: `aller à l'événement`,
      moreEvents: `Autres événements`,
      selectEvent: `Veuillez sélectionner un événement`,
      offline: `Vos photos personnelles seront bientôt en ligne!`,
      online: `Vos photos personnelles sont maintenant en ligne!`,
    },
    event: {
      text: `Veuillez entrer votre numéro de dossard pour voir vos photos.`,
      raceNumber: `Numéro de course`,
      or: `ou`,
      options: `Options`,
      search: `Chercher`,
      selectArticle: `Veuillez sélectionner un article`,
      added: `L'article a été ajouté au panier.`,
      close: `Continuer vos achats`,
      to: `Procéder au paiement`,
      no: `Désolé, aucune image trouvée. Vous voudrez peut-être choisir des options!`,
      flat: `achetez toutes les photos d'un numéro de course numériquement pour le téléchargement pour seulement`,
      flatUSB: `achetez toutes les photos d'un numéro de course numériquement sur une clé USB pour seulement`,
      more: `Charger plus de photos`,
      password: `Password`,
      error: `Nous avons des problèmes techniques. Veuillez réessayer plus tard ou nous envoyer un e-mail : support@go4it-foto.de`,
      privacy: `Vos photos personnelles sont hors ligne en raison d'une demande de confidentialité. Si ce n'est pas le cas, veuillez nous écrire à support@go4it-foto.de`,
    },
    reminder: {
      header: `Rappel postal`,
      register: `Inscription`,
      close: `Dos`,
      label: `J'accepte que les données saisies soient stockées sur go4it-foto. de et je serai informé par go4it-foto. de par email lorsque mes photos seront en ligne. Les données ne seront pas transmises à des tiers.`,
    },
    impressum: `Impressum`,
    termsAndConditions: `Conditions générales de vente`,
    privacy: `Confidentialité`,
    cookieText: `Ce site Web utilise les cookies à des fins fonctionnelles, pratiques et statistiques. Si vous continuez à naviguer sur le site, vous acceptez l'utilisation de cookies.`,
    cookieMore: `Plus d'informations`,
    cookieAccept: `J'accepte!`,
    loading: `Chargement des données`,
    card_declined: `Votre carte a été refusée.`,
    incorrect_cvc: `Le code de sécurité de votre carte est erroné.`,
    expired_card: `Votre carte a expiré.`,
    processing_error: `Une erreur s'est produite lors du traitement de votre carte. Essaie encore.`,
    pay_error: `L'ordre ne pouvait pas être sauvé. Veuillez réessayer plus tard.`,
    reminder_added: `Vous vous êtes inscrit avec succès et vous recevrez un e-mail dès que l'événement sera en ligne.`,
    privacy_added: `La demande nous a été envoyée avec succès et sera exécutée après vérification!`,
    error: "Quelque chose s'est mal passé, veuillez réessayer plus tard !",
    added: `Ajouté`,
    processed:
      "La commande est en cours de traitement. Veuillez ne pas fermer la fenêtre !",
    messages: {
      emailRequired: "Veuillez entrer une adresse e-mail",
      emailNotValid: "Veuillez entrer une adresse électronique valide",
      fristNameRequired: "Veuillez entrer un prénom",
      lastNameRequired: "Veuillez entrer un nom de famille",
      cityRequired: "Veuillez entrer une ville",
      zipCodeRequired: "Veuillez entrer un code postal",
      streetRequired: "Veuillez entrer une rue",
      numberRequired: "Veuillez saisir un numéro de maison",
      racenumberRequired: "Veuillez entrer un numéro de dossard",
      articleRequired: "Veuillez sélectionner un article",
      accept: "Veuillez accepter",
    },
  },
};

let lang = navigator.languages
  ? navigator.languages[0]
  : navigator.language || navigator.userLanguage;
lang = lang.substring(0, 2);

const i18n = new VueI18n({
  locale: lang || `de`,
  fallbackLocale: `en`,
  messages,
});

export default i18n;
