<template>
  <v-container>
    <v-carousel class="hidden-xs-only mt-4" cycle height="280">
      <v-carousel-item :src="require('@/assets/img1.jpg')"></v-carousel-item>
      <v-carousel-item :src="require('@/assets/img2.jpg')"></v-carousel-item>
      <v-carousel-item :src="require('@/assets/img3.jpg')"></v-carousel-item>
    </v-carousel>
    <v-row class="mt-4">
      <v-col cols="12" sm="6" md="3" v-for="event in top4" :key="event.id">
        <v-card class="shadow">
          <v-card-title>
            <h5>{{ event.name }}</h5>
          </v-card-title>
          <v-card-subtitle>
            {{ moment(event.date).format("DD.MM.YYYY") }}
          </v-card-subtitle>
          <v-card-text>
            <div v-if="!event.active">{{ $t("home.offline") }}</div>
            <div v-if="event.active">{{ $t("home.online") }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              v-if="!event.active"
              @click="
                () => {
                  showNotify = true;
                  eventId = event.id;
                }
              "
            >
              <v-icon right>far fa-envelope</v-icon> Notify
            </v-btn>
            <v-btn
              text
              color="success"
              v-if="event.active"
              :to="'events/' + event.id"
            >
              {{ $t("home.toEvent") }} <v-icon left> fa-chevron-right </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="shadow mt-6 mb-4">
      <v-card-title> {{ $t("home.moreEvents") }}: </v-card-title>
      <v-card-text>
        <v-combobox
          outlined
          dense
          :items="events"
          item-value="id"
          @change="
            (item) => {
              $router.push({ path: `/events/${item.id}` });
            }
          "
        />
      </v-card-text>
    </v-card>
    <privacy
      :eventId="eventId"
      :show="showPrivacy"
      :close="
        () => {
          showPrivacy = false;
        }
      "
    />
    <notify
      :eventId="eventId"
      :show="showNotify"
      :close="
        () => {
          showNotify = false;
        }
      "
    />
  </v-container>
</template>
<script>
import moment from "moment";
import { store } from "@/stores";
import Notify from "@/components/Notify";
import Privacy from "@/components/Privacy";

export default {
  name: "Home",
  components: { Privacy, Notify },
  data: () => {
    return {
      showPrivacy: false,
      showNotify: false,
      eventId: null,
    };
  },
  computed: {
    top4() {
      return store.state.events.slice(0, 4);
    },
    events() {
      return JSON.parse(JSON.stringify(store.state.events)).filter(
        (e) => e.active
      );
    },
  },
  methods: {
    moment,
  },
};
</script>
