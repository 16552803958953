<template>
  <tr>
    <td>
      <div class="mb-1">{{ eventName }}: {{ item.article.name }}</div>
      <span v-for="(pic, index) in item.pics" :key="index">
        {{ pic }}&nbsp;
      </span>
    </td>
    <td align="center" class="quantity">
      <v-layout row wrap>
        <v-flex xs12 sm2 md1>
          <v-text-field v-model="item.quantity" disabled></v-text-field>
        </v-flex>
        <v-flex xs6 sm2 md1>
          <v-btn icon color="green" @click="add()">
            <v-icon small>fa-plus</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs6 sm2 md1>
          <v-btn icon color="error" @click="remove()">
            <v-icon small>fa-minus</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 offset-sm2 sm2 offset-md4 md1>
          <v-btn icon color="error" @click="del()">
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </td>
    <td align="right">{{ (item.article.price * item.quantity) | currency }}</td>
  </tr>
</template>
<script>
import { store } from "@/stores";
export default {
  name: `CartListItem`,
  props: [`item`, `index`],
  computed: {
    eventName() {
      return store.state.events.find((e) => e.id === this.$props.item.eventId)
        .name;
    },
  },
  methods: {
    add() {
      store.updateItemQuantity(
        this.$props.index,
        this.$props.item.quantity + 1
      );
    },
    remove() {
      if (this.$props.item.quantity > 1)
        store.updateItemQuantity(
          this.$props.index,
          this.$props.item.quantity - 1
        );
    },
    del() {
      store.removeItem(this.$props.index);
    },
  },
};
</script>
<style scoped>
td {
  word-break: break-word;
}
</style>

